import { DecimalPipe, formatNumber, NgClass } from '@angular/common';
import {
    afterNextRender,
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiTypes, isHWS, RouterLinkDirective, VehicleFeature } from '@hyundai/ng-common-lib';
import type { DEALER_THEME } from '@hyundai/shared-lib';
import { InputSelectComponent } from '@hyundai/ui';
import { Store } from '@ngrx/store';
import { DialogService } from '@pixelgenau/ngx-dialog';
import { firstValueFrom, switchMap } from 'rxjs';
import { FilterTag } from '../filter/filter.tag';

@Component({
    selector: 'lib-quick-search',
    standalone: true,
    imports: [InputSelectComponent, RouterLinkDirective, DecimalPipe, NgClass],
    templateUrl: './quick-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrl: './quick-search.component.scss',
})
export class QuickSearchComponent {
    dialog = inject(DialogService);
    store = inject(Store);
    activatedRoute = inject(ActivatedRoute);
    router = inject(Router);

    isHWS = isHWS();

    listLink = input.required<string>();
    theme = input<DEALER_THEME>('light');

    constructor() {
        this.store.dispatch(VehicleFeature.vehicleActions.resetFilter());

        afterNextRender({
            write: () => {
                this.store.dispatch(VehicleFeature.vehicleActions.loadFilter());
            },
        });
    }

    numberOfVehicles = this.store.selectSignal(VehicleFeature.vehicleState.selectNumberOfVehicles);
    previewNumberOfVehicles = this.store.selectSignal(VehicleFeature.vehicleState.selectFilterPreviewNumberOfVehicles);
    inputs = this.store.selectSignal(VehicleFeature.vehicleState.selectFilterInputs);
    selectedFilter = this.store.selectSignal(VehicleFeature.vehicleState.selectFilterSelectedPreview);
    loadingPreviewNumberOfVehicles = this.store.selectSignal(
        VehicleFeature.vehicleState.selectFilterLoadingPreviewNumberOfVehicle,
    );

    models = computed(() => {
        const inputs = this.inputs();
        if (!inputs) return [];
        return inputs.models.values.map((m) => ({ value: m, label: m }));
    });

    price = computed(() => {
        const inputs = this.inputs();
        if (!inputs) return [];
        return inputs.price.values.map((reg) => ({
            value: reg.toString(),
            label: `${formatNumber(reg, 'de-DE')} EUR`,
        }));
    });

    selectedCondition = computed(() => {
        const filter = this.selectedFilter();
        return filter.condition as string[] | undefined;
    });

    milage = computed(() => {
        const inputs = this.inputs();
        if (!inputs) return [];

        const mileageFilter = inputs.mileage;

        if (mileageFilter.values.length === 0) return [];

        return mileageFilter.values.map((reg) => ({
            value: reg.toString(),
            label: `${formatNumber(reg, 'de-DE')} km`,
        }));
    });

    filterParam = computed(() => {
        const filter = this.selectedFilter();
        return Object.keys(filter).length > 0 ? JSON.stringify(filter) : undefined;
    });

    vehicleCount = computed(() => {
        const preview = this.previewNumberOfVehicles();
        const final = this.numberOfVehicles();
        if (preview !== null && preview !== false) return preview;
        if (final !== null && final !== false) return final;
        return 0;
    });

    async openFilterDialog() {
        const result = await firstValueFrom(this.dialog.openDialog(FilterTag).pipe(switchMap((d) => d.close$)));

        if (!result?.filter) return;

        const filter = this.selectedFilter();
        this.router.navigate([this.listLink()], {
            relativeTo: this.activatedRoute,
            queryParams: {
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filter) : undefined,
                page: 1,
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
        });
    }

    addFilter(key: keyof ApiTypes.FilterObject, data: unknown) {
        this.store.dispatch(
            VehicleFeature.vehicleActions.addFilter({
                filterObject: {
                    [key]: data,
                },
            }),
        );
        this.submitFilter();
    }
    removeFilter(key: keyof ApiTypes.FilterObject) {
        this.store.dispatch(
            VehicleFeature.vehicleActions.removeFilter({
                removeFilterObject: [key],
            }),
        );
        this.submitFilter();
    }

    submitFilter() {
        this.store.dispatch(VehicleFeature.vehicleActions.filter());
    }
}
