<section
    class="transition-colors"
    [ngClass]="{
        'bg-accent text-white': theme() === 'dark' || theme() === 'medium',
        'bg-white text-black': theme() === 'light',
    }"
>
    <div class="inner-page-wrapper flex flex-col pb-32 pt-24 lg:pb-0 lg:pt-40">
        <ng-content select="[title]" />
        <div class="flex items-center justify-center pb-16 pt-24 lg:justify-between lg:pt-64">
            @if (isHWS) {
                <div class="flex justify-center gap-20">
                    <button
                        class="button__condition"
                        [class.button__condition--active]="!selectedCondition()"
                        [ngClass]="theme() === 'light' ? 'light' : 'dark'"
                        (click)="removeFilter('condition')"
                    >
                        Alle
                    </button>
                    <button
                        class="button__condition"
                        [class.button__condition--active]="selectedCondition()?.includes('Neufahrzeug')"
                        [ngClass]="theme() === 'light' ? 'light' : 'dark'"
                        (click)="addFilter('condition', ['Neufahrzeug'])"
                    >
                        Neuwagen
                    </button>
                    <button
                        class="button__condition"
                        [class.button__condition--active]="selectedCondition()?.includes('Gebrauchtfahrzeug')"
                        [ngClass]="theme() === 'light' ? 'light' : 'dark'"
                        (click)="addFilter('condition', ['Gebrauchtfahrzeug'])"
                    >
                        Gebrauchtwagen
                    </button>
                </div>
            }
            <button
                class="ml-auto hidden items-center gap-8 font-headline text-16 leading-20"
                [ngClass]="{
                    'text-accent': theme() === 'light',
                    'lg:flex': isHWS,
                }"
                (click)="openFilterDialog()"
            >
                <i class="icon-filter"></i>
                Erweiterter Filter
            </button>
        </div>

        <div class="contents pb-24 lg:flex lg:flex-row lg:gap-24 lg:pb-64">
            <div class="flex flex-col gap-24 lg:contents">
                <ui-input-select
                    class="lg:flex-1"
                    [options]="models()"
                    [theme]="theme()"
                    (selectedChange)="
                        $event && $event.length > 0 ? addFilter('model', [$event]) : removeFilter('model')
                    "
                    withNullSelect
                    nullSelectCustomLabel="Alle Modelle"
                    label="Modell"
                    placeholder="Modell auswählen"
                />
                <ui-input-select
                    class="lg:flex-1"
                    [options]="price()"
                    [theme]="theme()"
                    (selectedChange)="$event ? addFilter('price', { max: $event }) : removeFilter('price')"
                    withNullSelect
                    nullSelectCustomLabel="Beliebiger Preis"
                    label="Preis bis"
                    placeholder="Preis auswählen"
                />
                <ui-input-select
                    class="lg:flex-1"
                    [options]="milage()"
                    [theme]="theme()"
                    (selectedChange)="$event ? addFilter('mileage', { max: $event }) : removeFilter('mileage')"
                    withNullSelect
                    label="Kilometer bis"
                    placeholder="Kilometer auswählen"
                    nullSelectCustomLabel="KM beliebig"
                />
            </div>

            <div class="mt-24 flex flex-col items-center gap-24 lg:mt-0 lg:w-[260px] lg:items-start">
                <a
                    class="button-primary transition-colors"
                    [ngClass]="{
                        'bg-white text-accent': theme() === 'medium',
                        'bg-blue text-white': theme() === 'dark',
                    }"
                    [queryParams]="{ filter: filterParam() }"
                    [hyundaiRouterLink]="listLink()"
                >
                    <span class="mr-8">
                        @if (loadingPreviewNumberOfVehicles()) {
                            <div
                                class="size-[18px] animate-spin rounded-full border-2 border-transparent border-l-white"
                            ></div>
                        } @else {
                            {{ vehicleCount() | number }}
                        }
                    </span>
                    <span>Fahrzeuge anzeigen</span>
                </a>
                <button
                    class="flex items-center gap-8 font-headline text-16 leading-20"
                    [ngClass]="{
                        'text-accent': theme() === 'light',
                        'lg:hidden': isHWS,
                    }"
                    (click)="openFilterDialog()"
                >
                    <i class="icon-filter"></i>
                    Erweiterter Filter
                </button>
            </div>
        </div>
    </div>
</section>
